import React from "react"
import { Link } from "gatsby"
import Layout from "../modules/layout"
import PostHero from "../modules/postHero"
import Illustration from "../../assets/svg/not-found.svg"

import styles from "./404.module.scss"

const NotFoundPage = () => (
  <Layout>
    <PostHero title="Oops, we can't find that page!" />
    <div className={styles.content}>
      <div className="grid">
        <div className="gridItem">
          <p>Return to the safety of <Link to="/">our homepage</Link> or discover more about <Link to="/products/">our products</Link>.</p>
        </div>
      </div>
    </div>
    <Illustration className={styles.illustration} />
  </Layout>
)

export default NotFoundPage
