import React from "react"

import styles from './postHero.module.scss'

const PostHero = (props) => (
  <section className={styles.postHero}>
    <div className="grid">
      <div className="gridItem large-8 large-offset-2 medium-10 medium-offset-1">
        <h1 className={styles.postHeroTitle}>{props.title}</h1>
      </div>
    </div>
  </section>
)

export default PostHero
